// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coach-js": () => import("./../../../src/pages/coach.js" /* webpackChunkName: "component---src-pages-coach-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meetings-js": () => import("./../../../src/pages/meetings.js" /* webpackChunkName: "component---src-pages-meetings-js" */),
  "component---src-pages-question-js": () => import("./../../../src/pages/question.js" /* webpackChunkName: "component---src-pages-question-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

